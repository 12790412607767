
body {
    background-color: #e0dede;
}

h1, h3, p {
    font-family: 'Montserrat', sans-serif;
    color: black;
}

h3.blue {
    font-weight: 600;
    color: #00ADB5;
}

p {
    font-size: 1rem;
    font-weight: 500;
    color: black;
}

div.body-padding {
    padding-left: 0;
    padding-right: 0;
}

h1.banner-title-edit {
    font-family: 'Sacramento', cursive;
    font-size: 4.5rem;
    font-weight: 600;
    padding-top: 2rem;
}

.ant-edit {
    max-width: 30%;    
    border-radius: 50%;
    border: 10px solid #00ADB5;
}

.ant-edit:hover {
    border-color: #007bff;
}

.banner-bg {
}

.body-edit {
    background-color: #e0dede;
    padding: 0;
}

.btn {
    border: 2px solid transparent;
}

.btn-info {
    background-color: #00ADB5;
}

a.btn-outline-primary:hover {
    color: #EEEEEE;
    border-color: #00ADB5;
    background-color: #00ADB5;
}

a.btn-outline-primary {
    color: #00ADB5;
    border-color: #00ADB5;
    margin-bottom: 20px;
}

.center-text {
    text-align: center;
}

.cinema-edit {
    max-width: 30%;
    border-radius: 50%;
    border: 10px solid #00ADB5;
}

.cinema-edit:hover {
    border-color: #007bff;
}

.contact-title{
    font-size: 1.5rem;
}

.copyright-spacing {
    margin-top: 10px;
}

.definition-edit {
    font-size: 2rem;
}

.footer-container {
    background-color: #00ADB5;
    padding: 30px 0px 30px 0px;
}

.github-icon {
    width: 28.5px;
}

.github-icon:hover {
    color: white;
}

.intro {
    padding: 30px 40px 0px 40px;
}



.morning {
    background-color: #00d4ff;
}

.afternoon {
    background-color: #00ADB5;
}

.copyright-edit {
    font-size: .75rem;
}

.evening {
    background-color: #21094e;
}

.icons {
    color: #222831;
}

.icons:hover {
    color: white;
}

.icon-edit {
    margin: 0 12% 0 12%;
    max-width: 7%;
}

.icon-row {
    justify-content: center;
}

.pro-grammer {
    text-decoration: underline;
}

.project-button {
    margin: 0 5px 0 5px;
}

.project-container {
    background-color: #e0dede;
    margin: 0;
    padding: 1rem 0 1rem 0;
    border-radius: 30px;
}

.project-date {
    font-size: 0.9rem;
    margin-top: 10px;
}

.project-img {
    padding-top: 2%;
}


.project-list-container {
    background-color: #00ADB5;
    border-radius: 40px;
    padding: 2rem 2rem 2rem 2rem;
}

.project-name {
    color: #00ADB5;
    font-weight: 600;
    padding-top: 1rem;
    font-size: 1.4rem;
}

.project-syn {
    margin-top: 0;
}

.project-text {
    text-align: center;
}

.project-ts-img {
    width: 65px;
    margin-left: 10px;
}

.quote-margin{
    margin: 20px 0px 0px 0px;
}

.quote-author, .quote-content{
    font-size: 1rem;
    font-weight: 500;
}

.quote-author {
    padding-bottom: 20px;
}

.star-blink {
    position: absolute;
    width: 15%;
    height: auto;
}

.transitionRule {
    color: #00ADB5;
    margin: 50px auto 50px auto;
    border-style: dotted;
    border-width: 10px;
    border-bottom: none;
    border-top: 10px dotted #00ADB5;
    width: 6%;
}

.universe-edit {
    width: 200px;
    height: auto;
    margin: 20px 0px 0px 10px;
}

@media screen and (min-width: 100px) {
    .icon-edit {
        max-width: 15%;
        margin: 0 5% 0 5%;
    }
}

@media screen and (min-width: 500px) {

    h3.blue {
        padding-bottom: 2rem;
    }

    .ant-edit {
        max-width: 90%;
    }

    .icon-edit {
        max-width: 10%;
    }

    .cinema-edit {
        max-width: 30%;
    }

    .project-ts-img {
        width: 75px;    
    }
}

@media screen and (min-width: 900px) {
    div.project-img {
        grid-column-start: 2;
        grid-row-start: 1;
    }
    
    h1.banner-title-edit {
        font-size: 7.5rem;
    }

    h3.blue {
        font-size: 4rem;
        padding-bottom: 2.5rem;
    }

    p {
        font-size: 1.5rem;
    }

    .icon-edit {
        max-width: 6%;
    }

    a.btn-outline-primary {
        padding: .7rem 5rem;
        font-size: 1.5rem;
    }

    .copyright-edit {
        font-size: 1rem;
    }

    .definition-edit {
        font-size: 3.5rem;
    }
    
    .universe-edit {
        width: 400px;
        height: auto;
    }

    .project-name {
        font-size: 3rem;
    }

    .project-text {
        padding-left: 1.5rem;
    }

    .quote-author, .quote-content {
        font-size: 1.5rem;
    }

}

@media screen and (min-width: 990px) {
    .grid {
        display: grid;
        grid-template-columns: 1.6fr .35fr;
        grid-column-gap: .5em;
        grid-auto-columns: auto;
    }

    .col-9 {
        max-width: 100%;
    }

    .cinema-edit {
        max-width: 90%;
    }

    .project-text {
        text-align: left;
        padding-left: 2rem;
    }
}

@media  screen and (min-width: 1400px) {
    h1.banner-title-edit {
        font-size: 10.5rem;
    }

    h3.blue {
        font-size: 5rem;
    }

    p {
        font-size: 1.8rem;
        margin-top: 1.25rem;
    }

    a.btn-outline-primary {
        padding: .9rem 5rem;
        font-size: 1.8rem;
        margin-top: 1rem;
    }

    .icon-edit {
        max-width: 5%;
    }
    
    .universe-edit {
        width: 600px;
        height: auto;
    }

    .project-date {
        font-size: 1.2rem;
    }

    .project-name {
    font-size: 3rem;    
}

    .quote-author, .quote-content {
        font-size: 2rem;
    }
}

@media  screen and (min-width: 1800px) {
    .icon-edit {
        max-width: 4%;
    }
}

@media  screen and (min-width: 2400px) {
    .icon-edit {
        max-width: 3%;
    }
}
